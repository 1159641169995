<script setup lang="ts">
import EyeIcon from '~/assets/icons/accessibility/eye.svg'
import EyeClosedIcon from '~/assets/icons/accessibility/eyeClosed.svg'

const showPassword = ref(false)
const handleIconClick = () => {
  showPassword.value = !showPassword.value
}
</script>

<template>
  <FormKit
    :type="showPassword ? 'text' : 'password'"
  >
    <template #suffixIcon>
      <div class="h-7 w-7 ml-1.5 cursor-pointer hover:bg-beige p-1 rounded" @click="handleIconClick">
        <EyeIcon v-if="showPassword" />
        <EyeClosedIcon v-else />
      </div>
    </template>
  </FormKit>
</template>
